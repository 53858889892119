@font-face {
  font-family: "melikan";
  src: url("./assets/fonts/melikan.otf") format("opentype");
}

@import './assets/css/fonts.css';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

html {
	background: var(--bg-dark);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}	

:root {
	--border-radius: .5rem;
	--bg-dark: #030303;
	--text-white: #fffffd;
	--text-gray: #bfbfbf;
	--text-black: #131923;
	--text-dark: #666;
  
	--top-margin: 4rem;
	--negative-top-margin: -4rem;

	--color-blue: #006bf5;
	--color-orange: #ff7c48;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  background: var(--bg-beige);
}

.darken::after {
	content: "";
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 3;
}

.container {
	width: min(100%, 1800px);
	margin: 0 auto;
	padding: 0 1rem;
}
  
* {
	font-family: 'FivoSansModern', sans-serif;
}

  h1, h2, h3, h4, h5, h6 {
	font-family: "BeautiqueDisplay", serif !important;
	font-weight: 400 !important;
	letter-spacing: 1px;
	text-transform: uppercase;
  }
  
  h1 {
	  font-size: 2rem;
  }

  p {
    font-size: 1.2rem;
    color: var(--text-white);
    line-height: 1.2;
  }
  
  p, span, a {
	  font-weight: 400;
	  letter-spacing: 1px;
  }

a {
  color: var(--text-white);
  text-decoration: none;
}

a:hover {
  color: var(--text-gray);
  text-decoration: underline;
}
  
  button {
	  font-size: .9rem;
	  font-weight: 400;
	  letter-spacing: 1px;
	  padding: .5rem 1rem;
	  transition: all .15s;
  }

.hero__text {
  max-width: 60rem;
  text-align: left;
}
  
button:hover {
	cursor: pointer;
	transform: translateY(-.1rem);
}
  
.btn__primary {
	padding: .5rem 1rem;
	border-radius: var(--border-radius);
}
  
.link__button {
	padding: 1rem 2rem;
	text-decoration: none;
	font-size: 1rem;
	text-transform: uppercase;
	letter-spacing: 1px;
	transition: all .15s;
	border-radius: var(--border-radius);
}

.link__button:hover {
	transform: translateY(-.1rem);
}

.link__button__black {
	background: var(--bg-dark);
	color: var(--text-white);
}

.link__button__white {
	background: var(--text-white);
	color: var(--text-dark);
	border: 1px solid var(--text-dark);
}

  input:focus, textarea:focus {
	  outline: none;
  }

.text-center {
	text-align: center;
}

.image__text {
	display: flex;
	padding: 2rem 0;
	gap: 4rem;
	align-items: start;
}

.image__text img {
	max-width: 50%;
	border-radius: var(--border-radius);
}

.text__section {
	text-align: left;
	padding: 2rem 0;
	width: 100%;
}

.text__section p {
	max-width: 45rem;
}

.cta__section {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	max-width: 45rem;
	margin: 0 auto;
	padding: 4rem 0;
	gap: 1.5rem;
	line-height: 1.8;
}

.cta__section p {
	text-align: center;
	font-size: 1rem;
}

.contact__title {
  margin: 0 !important;
  padding: 0 !important;
}

.text__container {
    margin-top: auto;
    min-width: min(100%, 30rem);
    width: 100%;
    position: relative;
}

.text__container h2 {
    color: var(--text-white);
    font-family: 'FivoSansModern', sans-serif !important;
    font-size: 4rem;
    padding: 4rem 0;
}

.text__container p {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: var(--text-white);
  line-height: 1.2;
  max-width: 60rem;
}
.text__image__section img {
    margin-top: auto;
    max-width: 50%;
    height: auto;
}

.text__image__section {
    display: flex;
    gap: 5rem;
    padding: 10rem 0;
    max-width: 100% !important;
}

.contact__wrap {
    display: flex;
    border-bottom: 1px solid var(--text-gray);
    text-align: right;
    /* padding: .3rem 0; */
}

.contact__link {
    color: var(--text-white);
    text-decoration: none;
    width: 100%;
    margin-top: 1rem;
    position: absolute;
    right: 0;
    bottom: .3rem;
}

.contact__link:hover {
    color: var(--text-gray);
    text-decoration: underline;
}

.info__box {
    text-transform: uppercase;
    min-width: 30rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    color: var(--text-white);
}

.info__box h3 {
    font-family: 'FivoSansModern', sans-serif !important;
    font-weight: 400;
}

.info__box__text {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.right__info {
    display: flex;
    flex-direction: column;
    gap: .2rem;
    min-width: 16rem;
}

.right__info a {
    color: var(--text-white);
    text-decoration: none;
}

.right__info a:hover {
    color: var(--text-gray);
    text-decoration: underline;
}

.landing__info {
    display: flex;
    gap: 15rem;
    padding-bottom: 10rem;
}

.arrow__link {
  display: flex;
  align-items: center;
  justify-content: end;
}

.mt-1 {
    margin-top: .75rem;
}

.slick-next {
  display: none !important;
}
@media screen and (max-width: 1200px) {
    .landing__info {
        gap: 5rem;
    }

    .info__box {
        min-width: 20rem;
    }

    .info__box__text {
      flex-direction: column;
      gap: 1rem !important;
    }
}

@media screen and (max-width: 1024px) {

    .info__box__text h3 {
        text-decoration: underline;
    }

    .text__image__section {
        flex-direction: column;
        padding: 5rem 0;
    }

    .text__image__section img {
        max-width: min(100%, 35rem);
        margin: 0 auto;
    }

    .reversed {
        flex-direction: column-reverse !important;
        padding-top: 0;
        gap: 3rem;
    }
}

@media screen and (max-width: 768px) {
	.container {
		width: 100%;
	}

	.image__text {
		flex-direction: column;
		gap: 2rem;
	}

	.image__text img {
		max-width: 100% !important;
	}

	.landing__info {
        flex-direction: column;
        padding-bottom: 4rem;
    }

  .text__container h2 {
      font-size: 2.5rem;
  }
}

/* ANIMATIONS */
.fly-left {
	-webkit-animation: flyInFromLeft .5s ease-in-out;
	animation: flyInFromLeft .5s ease-in-out;
}
  
.fly-right {
	-webkit-animation: flyInFromRight .5s ease-in-out;
	animation: flyInFromRight .5s ease-in-out;
}
  
.fly-bottom {
	-webkit-animation: flyInFromBottom .5s ease-in-out;
	animation: flyInFromBottom .5s ease-in-out;
}
  
.fade-in {
	-webkit-animation: fadeIn .5s ease-in-out;
	animation: fadeIn .5s ease-in-out;
}

/* Start animations with forwards */
.start-animation.fly-left-late {
	-webkit-animation: flyInFromLeft .5s ease-in-out forwards;
	animation: flyInFromLeft .5s ease-in-out forwards;
}
  
.start-animation.fly-right-late {
	-webkit-animation: flyInFromRight .5s ease-in-out forwards;
	animation: flyInFromRight .5s ease-in-out forwards;
}
  
.start-animation.fly-bottom-late {
	-webkit-animation: flyInFromBottom .5s ease-in-out forwards;
	animation: flyInFromBottom .5s ease-in-out forwards;
}
  
.start-animation.fade-in-late {
	-webkit-animation: fadeIn .5s ease-in-out forwards;
	animation: fadeIn .5s ease-in-out forwards;
}
	
/* fadeIn */
@-webkit-keyframes fadeIn {
	from { opacity: 0.3; }
	to { opacity: 1; }
}
@-moz-keyframes fadeIn {
	from { opacity: 0.3; }
	to { opacity: 1; }
}
  @-o-keyframes fadeIn {
	from { opacity: 0.3; }
	to { opacity: 1; }
  }
  @keyframes fadeIn {
	from { opacity: 0.3; }
	to { opacity: 1; }
  }
  
  /* flyInFromRight */
@-webkit-keyframes flyInFromRight {
	from { transform: translateX(20%); }
	to { transform: translateX(0); opacity: 1; }
  }
@-moz-keyframes flyInFromRight {
  from { transform: translateX(20%); }
  to { transform: translateX(0); opacity: 1; }
}
  @-o-keyframes flyInFromRight {
	from { transform: translateX(20%); }
	to { transform: translateX(0); opacity: 1; }
  }
  @keyframes flyInFromRight {
	from { transform: translateX(20%); }
	to { transform: translateX(0); opacity: 1; }
  }
  
  /* flyInFromLeft */
  @-webkit-keyframes flyInFromLeft {
	from { transform: translateX(-10%); }
	to { transform: translateX(0); opacity: 1; }
  }
  @-moz-keyframes flyInFromLeft {
	from { transform: translateX(-10%); }
	to { transform: translateX(0); opacity: 1; }
  }
  @-o-keyframes flyInFromLeft {
	from { transform: translateX(-10%); }
	to { transform: translateX(0); opacity: 1; }
  }
  @keyframes flyInFromLeft {
	from { transform: translateX(-10%); }
	to { transform: translateX(0); opacity: 1; }
  }
  
  /* flyInFromBottom */
  @-webkit-keyframes flyInFromBottom {
	from { transform: translateY(20%); }
	to { transform: translateY(0); opacity: 1; }
  }
  @-moz-keyframes flyInFromBottom {
	from { transform: translateY(20%); }
	to { transform: translateY(0); opacity: 1; }
  }
  @-o-keyframes flyInFromBottom {
	from { transform: translateY(20%); }
	to { transform: translateY(0); opacity: 1; }
  }
  @keyframes flyInFromBottom {
	from { transform: translateY(20%); }
	to { transform: translateY(0); opacity: 1; }
  }
  
  /* moveUpDown */
  @-webkit-keyframes moveUpDown {
	0%, 100% { transform: translateY(-.5rem); }
	50% { transform: translateY(.5rem); }
  }
  @-moz-keyframes moveUpDown {
	0%, 100% { transform: translateY(-.5rem); }
	50% { transform: translateY(.5rem); }
  }
  @-o-keyframes moveUpDown {
	0%, 100% { transform: translateY(-.5rem); }
	50% { transform: translateY(.5rem); }
  }
  @keyframes moveUpDown {
	0%, 100% { transform: translateY(-.5rem); }
	50% { transform: translateY(.5rem); }
  }

  @keyframes zoomIn {
    0% {
        background-size: 100%;
    }
    100% {
        background-size: 120%;
    }
}