@font-face {
	font-family: 'BeautiqueDisplay';
	src: url('../fonts/BeautiqueDisplay-Black.otf') format('opentype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'BeautiqueDisplay';
	src: url('../fonts/BeautiqueDisplay-BlackItalic.otf') format('opentype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'BeautiqueDisplay';
	src: url('../fonts/BeautiqueDisplay-Bold.otf') format('opentype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'BeautiqueDisplay';
	src: url('../fonts/BeautiqueDisplay-BoldItalic.otf') format('opentype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'BeautiqueDisplay';
	src: url('../fonts/BeautiqueDisplay-Italic.otf') format('opentype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'BeautiqueDisplay';
	src: url('../fonts/BeautiqueDisplay-Light.otf') format('opentype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'BeautiqueDisplay';
	src: url('../fonts/BeautiqueDisplay-LightItalic.otf') format('opentype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'BeautiqueDisplay';
	src: url('../fonts/BeautiqueDisplay-Medium.otf') format('opentype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'BeautiqueDisplay';
	src: url('../fonts/BeautiqueDisplay-MediumItalic.otf') format('opentype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'BeautiqueDisplay';
	src: url('../fonts/BeautiqueDisplay-Regular.otf') format('opentype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'FivoSansModern';
	src: url('../fonts/FivoSansModern-Bold-Oblique.otf') format('opentype');
	font-weight: 700;
	font-style: oblique;
}

@font-face {
	font-family: 'FivoSansModern';
	src: url('../fonts/FivoSansModern-Bold.otf') format('opentype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'FivoSansModern';
	src: url('../fonts/FivoSansModern-ExtBlack-Oblique.otf') format('opentype');
	font-weight: 900;
	font-style: oblique;
}

@font-face {
	font-family: 'FivoSansModern';
	src: url('../fonts/FivoSansModern-ExtBlack.otf') format('opentype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'FivoSansModern';
	src: url('../fonts/FivoSansModern-Heavy-Oblique.otf') format('opentype');
	font-weight: 800;
	font-style: oblique;
}

@font-face {
	font-family: 'FivoSansModern';
	src: url('../fonts/FivoSansModern-Heavy.otf') format('opentype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'FivoSansModern';
	src: url('../fonts/FivoSansModern-Medium-Oblique.otf') format('opentype');
	font-weight: 500;
	font-style: oblique;
}

@font-face {
	font-family: 'FivoSansModern';
	src: url('../fonts/FivoSansModern-Medium.otf') format('opentype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'FivoSansModern';
	src: url('../fonts/FivoSansModern-Regular-Oblique.otf') format('opentype');
	font-weight: 400;
	font-style: oblique;
}

@font-face {
	font-family: 'FivoSansModern';
	src: url('../fonts/FivoSansModern-Regular.otf') format('opentype');
	font-weight: 400;
	font-style: normal;
}