.loading__screen {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    height: 100vh;
    background-color: #000;
}

.loading__screen video {
    height: 500px;
}

@media screen and (max-width: 768px) {
    .loading__screen video {
        height: 250px;
    }
}