#contact h1 {
    font-size: 4rem;
    color: var(--text-white);
    text-align: left;
    padding: 3rem 0;
    font-family: 'FivoSansModern', sans-serif !important;
}

#contact .landing {
    display: flex;
    gap: 3rem;
    position: relative;
}

.contact__info {
    align-self: last baseline;
}
.contact__image {
    width: 50%;
}

#contact .back__nav__link {
    position: absolute;
    left: 1rem;
    top: 1rem;
    display: flex;
    gap: .5rem;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    z-index: 1;
}
@media screen and (max-width: 768px) {
    #contact .landing {
        flex-direction: column;
        margin-left: 0;
        margin-top: 2rem;
    }

    .contact__image {
        width: 100%;
    }
}