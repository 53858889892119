footer {
    margin-top: 10rem;
    padding: 2rem 0;
}

footer img {
    margin-bottom: 5rem;
}

.left__footer {
    margin-top: .3rem;
}

.footer__content {
    display: flex;
    gap: 8rem;
}

.right__footer__wrap {
    border-bottom: 1px solid var(--text-gray);
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0;
}

.right__footer {
    width: 100%;
    display: flex;
    gap: 20%;
}

.right__footer h3 {
    font-size: 2.5rem;
    color: var(--text-white);
    font-family: 'FivoSansModern', sans-serif !important;
    margin-block-end: 1rem;
}

.right__footer .footer__links {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-width: 15rem;
    margin-top: .2rem;
}

.right__footer .footer__links a {
    color: var(--text-white);
    font-family: 'FivoSansModern', sans-serif !important;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 1rem;
}

.right__footer .footer__links a:hover {
    color: var(--text-gray);
}

.french__group p {
    font-size: .9rem;
    color: var(--text-gray);
}

.newsletter__link {
    margin-bottom: .3rem;
    display: flex;
    gap: .5rem;
    align-items: center;
}

.newsletter__wrap {
    min-width: 15rem;
    align-self: flex-end;
}

@media screen and (max-width: 1400px) {
    .footer__content {
        gap: 5rem;
    }

    .right__footer {
        gap: 10%;
    }

    .french__group p {
        font-size: .9rem;
    }

    .right__footer {
        flex-direction: column;
        gap: 2rem;
    }

    .right__footer h3 {
        font-size: 2rem;
    }

    .right__footer .footer__links {
        gap: 0.5rem;
    }
}

@media screen and (max-width: 1024px) {
    footer {
        margin-top: 5rem;
    }

    .footer__content {
        flex-direction: column;
        gap: 2rem;
    }
}

@media screen and (max-width: 768px) {
    .right__footer {
        flex-direction: column-reverse;
    }

    .footer__links {
        flex-direction: row !important;
        gap: 1rem !important;
        font-size: .8rem !important;
        padding: 1.5rem 0;
    }

    .newsletter__link {
        margin-top: 1rem;
    }

    .newsletter__wrap {
        min-width: 0;
    }
}